const AVAILABLE_LOCALES = ['en', 'zh', 'ru', 'pt', 'ar', 'de', 'es', 'fr', 'it', 'tr', 'sr', 'ja', 'ko', 'th', 'pl', 'ro', 'nl', 'el', 'cs', 'sv', 'hu', 'bg', 'da', 'fi', 'sk', 'no', 'hr', 'fa', 'id', 'vi'];
const MAIN_LOCALE = 'en';

const languagesMap = {
  'en': { flag: '🇺🇸', name: 'English' },
  'zh': { flag: '🇨🇳', name: 'Chinese' },
  'ru': { flag: '🇷🇺', name: 'Russian' },
  'pt': { flag: '🇵🇹', name: 'Portuguese' },
  'ar': { flag: '🇸🇦', name: 'Arabic' },
  'de': { flag: '🇩🇪', name: 'German' },
  'es': { flag: '🇪🇸', name: 'Spanish' },
  'fr': { flag: '🇫🇷', name: 'French' },
  'it': { flag: '🇮🇹', name: 'Italian' },
  'tr': { flag: '🇹🇷', name: 'Turkish' },
  'sr': { flag: '🇷🇸', name: 'Serbian' },
  'ja': { flag: '🇯🇵', name: 'Japanese' },
  'ko': { flag: '🇰🇷', name: 'Korean' },
  'th': { flag: '🇹🇭', name: 'Thai' },
  'pl': { flag: '🇵🇱', name: 'Polish' },
  'ro': { flag: '🇷🇴', name: 'Romanian' },
  'nl': { flag: '🇳🇱', name: 'Dutch' },
  'el': { flag: '🇬🇷', name: 'Greek' },
  'cs': { flag: '🇨🇿', name: 'Czech' },
  'sv': { flag: '🇸🇪', name: 'Swedish' },
  'hu': { flag: '🇭🇺', name: 'Hungarian' },
  'bg': { flag: '🇧🇬', name: 'Bulgarian' },
  'da': { flag: '🇩🇰', name: 'Danish' },
  'fi': { flag: '🇫🇮', name: 'Finnish' },
  'sk': { flag: '🇸🇰', name: 'Slovak' },
  'no': { flag: '🇳🇴', name: 'Norwegian' },
  'hr': { flag: '🇭🇷', name: 'Croatian' },
  'fa': { flag: '🇮🇷', name: 'Persian' },
  'id': { flag: '🇮🇩', name: 'Indonesian' },
  'vi': { flag: '🇻🇳', name: 'Vietnamese' }
};

const Menu = {
  init() {
    // Handling click event for opening the menu
    document.querySelectorAll('.jsMenuOpen').forEach(button => {
      button.addEventListener('click', function(event) {
        event.preventDefault();
        document.querySelector('.jsMenu').classList.add('isActive');
      });
    });

    // Handling click event for closing the menu
    document.querySelectorAll('.jsMenuClose').forEach(button => {
      button.addEventListener('click', function(event) {
        event.preventDefault();
        document.querySelector('.jsMenu').classList.remove('isActive');
      });
    });
  }
}

const Header = {
  header: document.querySelector('.jsPageHeader'),

  setState() {
    let scroll = window.scrollY;
    if (scroll > 0) {
      this.header.classList.add('--scrolled');
    } else {
      this.header.classList.remove('--scrolled');
    }
  },

  init() {
    window.addEventListener('scroll', () => {
      this.setState();
    });
    this.setState();
  }
}

const TestEvents = {
  init() {
    if(window.IS_TESTNC) {
      if(!localStorage.getItem('notFromTest')) {
        localStorage.setItem('fromTest', true);
      }
    } else {
      if(localStorage.getItem('fromTest')) {
        localStorage.removeItem('fromTest');
        ga('send', 'event', 'test-n-c', 'lead', window.location.href);
      }
      localStorage.setItem('notFromTest', true);
    }
  }
}

const Features = {
  showItem(index) {
    // Handle adding and removing 'isActive' class
    document.querySelectorAll(`[data-features-link]`).forEach(el => {
      if (el.getAttribute('data-features-link') === index) {
        el.classList.add('isActive');
      } else {
        el.classList.remove('isActive');
      }
    });

    // Handle showing and hiding elements
    document.querySelectorAll(`[data-features-image]`).forEach(el => {
      if (el.getAttribute('data-features-image') === index) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    });
  },

  init() {
    this.showItem('0');

    // Replace .on('mouseenter') for '[data-features-link]' elements
    document.querySelectorAll('[data-features-link]').forEach(element => {
      element.addEventListener('mouseenter', () => {
        const thisIndex = element.getAttribute('data-features-link');
        this.showItem(thisIndex);
      });
    });
  }
}



function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

const LanguageSwitch = {
  changeLanguage(newLang) {
    setCookie('selectedLanguage', newLang, 30);
    const pathname = window.location.pathname;
    const localePart = pathname.substr(1, 2);

    const basePath = AVAILABLE_LOCALES.includes(localePart) && pathname !== '/faq/'
      ? pathname.substring(3)
      : pathname;
    
    const redirectUrl = newLang === MAIN_LOCALE
      ? (basePath === '' ? '/' : basePath)
      : `/${newLang}${basePath}`

    window.location.href = redirectUrl;
  },

  init() {
    const self = this;

    document.querySelectorAll('.jsLangLink').forEach(function(element) {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        const newLang = this.getAttribute('data-lang');
        self.changeLanguage(newLang);
      });
    });

    document.querySelectorAll('.jsLangSelect').forEach(function(select) {
      select.addEventListener('change', function() {
        const newLang = this.value;
        self.changeLanguage(newLang);
      });
    });

    document.querySelectorAll('.jsLangSelect option').forEach(function(option) {
      const languageObject = languagesMap[option.value];

      if (languageObject) {
        option.innerText = languageObject.flag + ' ' + option.value;
      }
    });
  },
};

const IntroVideo = {
  init() {
    if (document.querySelector('.jsPlayIntroVideo')) {
      document.querySelector('.jsPlayIntroVideo').addEventListener('click', function(event) {
        event.preventDefault();
        const videoElement = document.querySelector('.jsIntroVideo');
        videoElement.play();
        event.currentTarget.style = 'display: none';
      });
    }
  }
}

const Answers = {
  init() {
    document.querySelectorAll('.jsOpenAnswer').forEach(function(element) {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        const id = event.currentTarget.getAttribute('data-id');
        const answerElement = document.querySelector(`.jsQuestionAnswer[data-id="${id}"]`);
        answerElement.classList.toggle('isActive');
      });
    });
  }
}

const ExpandingList = {
  init() {
    document.querySelectorAll('.jsExpandingListItem').forEach((elem, index) => {
      if (index > 4) {
        elem.style = 'display: none';
      }
    });

    const ShowMoreLink = document.querySelector('.jsExpandingListShowMore');

    if (ShowMoreLink) {
      ShowMoreLink.addEventListener('click', (event) => {
        event.preventDefault();
        ShowMoreLink.style = 'display: none';

        document.querySelectorAll('.jsExpandingListItem').forEach((elem) => {
          elem.style = 'display: block';
        });
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  Menu.init();
  Header.init();
  Features.init();
  LanguageSwitch.init();
  TestEvents.init();
  IntroVideo.init();
  Answers.init();
  ExpandingList.init();
});
